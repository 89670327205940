<template>
  <div class="sale-qrcode">
    <div id="imgBox">
      <div class="qrcode-box">
        <img
          :src="sale.img"
          alt=""
        >
      </div>
      <div
        class="text-box"
        v-html="sale.describe"
      ></div>
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      loading: false,
      sale: {
        img: 'https://ceshiren.com/uploads/default/original/3X/3/1/3113161a2ea392753b559ba77099ee2135651e82.jpeg',
        describe: '扫码添加班主任<br/>及时了解更多信息课程'
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    //获取数据
    getData () {
      this.loading = true
      // axios.get(`${location.origin}/backend/config/${this.$route.query.config}`)
      axios.get(`https://dev.design.ceba.ceshiren.com/backend/config/${this.$route.query.config}`)
        .then(res => {
          console.log(res);
          if (res.data.data) {
            this.sale = res.data.data.sale

            if (res.data.data.css) {
              this.createCssLink(res.data.data.css)
            } else {
              // this.createCssLink('default')
            }
          }

        })
    },
    //避免重复载入 
    removeRemoteCss () {
      const linkList = document.querySelectorAll('#remote_css')
      console.log(linkList);
      for (let i = 0; i < linkList.length; i++) {
        linkList[i].parentNode.removeChild(linkList[i]);
      }
    },

    // 创建link标签引用远程css
    createCssLink (css_name) {
      this.removeRemoteCss()
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.id = 'remote_css'
      const cssPath = `https://dev.design.ceba.ceshiren.com/backend/css/${css_name}.css`
      link.href = cssPath
      document.head.appendChild(link)
      console.log('css加载完成');
      // setTimeout(() => { this.domtoimg() }, 1500)
      setTimeout(() => {
        this.$toImage.domtoimg(document.getElementById('imgBox'))
        this.loading = false
      }, 1500)

    },
  }
}
</script>

<style scoped lang="less">
// .sale-qrcode {
//   display: flex;
//   justify-content: center;
// }
// #imgBox {
//   width: 100%;
//   max-width: 420px;
//   background-color: #fff;
// }
// .qrcode-box {
//   width: 100%;
//   padding: 0 20px;
//   display: flex;
//   justify-content: center;
// }
// .qrcode-box img {
//   width: 100%;
// }
// .text-box {
//   text-align: center;
//   font-family: "FangSong ";
//   color: #f48d8f;
//   font-size: 21px;
//   font-weight: 700;
//   letter-spacing: 3px;
// }
</style>